import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isFunction } from 'lodash';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as historyActions from '../../../actions/history';
import * as pageActions from '../../../actions/page';
import * as historyUtils from '../../../utils/historyUtils';

import historyShape from '../../../shapes/historyShape';
import routerShape, { routerLocationShape } from '../../../shapes/routerShape';

import TopLeftButton from '../../../components/TopBar/TopLeftButton';
import withRouter from '../../WithRouter';

class TopLeftButtonContainer extends Component {
  static propTypes = {
    isCartShown: PropTypes.bool,
    history: historyShape.isRequired,
    onHomeClick: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    toggleMobileMenu: PropTypes.func.isRequired,
    onExternalBackClick: PropTypes.func,

    /* React Router props */
    location: routerLocationShape.isRequired,
    router: routerShape.isRequired,
  };

  static defaultProps = {
    isCartShown: false,
    onExternalBackClick: null,
  };

  get currentLocation() {
    const { history } = this.props;

    return history.locations[history.currentIndex] ?? {};
  }

  get previousLocation() {
    const { history } = this.props;

    if (this.currentLocation.pathname === '/') return null;

    return historyUtils.determinePreviousLocation(history.currentIndex, history.locations);
  }

  get previousRoutesFlow() {
    return this.previousLocation && historyUtils.findRoutesFlow(this.previousLocation.flow);
  }

  get previousRoute() {
    return (
      this.previousLocation &&
      historyUtils.findLocationRoute(this.previousLocation, this.previousRoutesFlow.routes)
    );
  }

  get caption() {
    const { isCartShown } = this.props;

    if (isCartShown) return 'Back';

    const { currentLocation } = this;

    if (currentLocation.state?.backButtonOverwrite)
      return currentLocation.state?.backButtonOverwrite;

    return this.previousRoute ? this.previousRoute.caption : null;
  }

  get isBackButtonForceShow() {
    return this.currentLocation.state?.backButtonForceShow;
  }

  handleGoBackClick = () => {
    const { isCartShown, goBack, router, location, onExternalBackClick } = this.props;
    const { pathname, search } = location;

    if (isCartShown) {
      if (isFunction(onExternalBackClick)) {
        // Call external function if it is passed to TopBar
        return onExternalBackClick();
      }
      return router.replace({
        pathname,
        search: historyUtils.setSearchParams(search, { showCart: false }),
      });
    }
    return goBack();
  };

  handleMobileMenuClick = () => {
    const { toggleMobileMenu } = this.props;

    toggleMobileMenu();
  };

  render() {
    const { isCartShown, onHomeClick } = this.props;

    return (
      <TopLeftButton
        isCartShown={isCartShown}
        caption={this.caption}
        previousLocation={this.previousLocation}
        isBackButtonForceShow={this.isBackButtonForceShow}
        onHomeClick={onHomeClick}
        onGoBackClick={this.handleGoBackClick}
        onMobileMenuClick={this.handleMobileMenuClick}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  history: state.history,
  location: state.router.location,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goBack: historyActions.goBack,
      toggleMobileMenu: pageActions.toggleMobileMenu,
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopLeftButtonContainer));
