import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';

import {
  CONTACT_EMAIL,
  COUNTRY_CODE,
  COUNTRY_NAME,
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  OFFICIAL_SITE_LINK,
} from '../../../settings';

import { ReactComponent as FacebookLogo } from './icon-fb.svg';
import { ReactComponent as InstaLogo } from './icon-insta.svg';

function Footer() {
  const giftCardUrl = useSelector((state) => state.api.settings.giftCardUrl);

  return (
    <div className="footer kale">
      <ul className="hidden-xs hidden-sm footer-links">
        <li>
          <a href={OFFICIAL_SITE_LINK} rel="noopener noreferrer" target="_blank">
            <Typography
              className="FooterLink"
              variant="subtitle2"
              color="primary.light"
              fontWeight="600"
            >
              Our Website
            </Typography>
          </a>
        </li>
        <li>
          <a href={`/privacy-policy/${COUNTRY_CODE.toLowerCase()}/`}>
            <Typography
              className="FooterLink"
              variant="subtitle2"
              color="primary.light"
              fontWeight="600"
            >
              Privacy Policy
            </Typography>
          </a>
        </li>
        <li>
          <a href="/terms-and-conditions/">
            <Typography
              className="FooterLink"
              variant="subtitle2"
              color="primary.light"
              fontWeight="600"
            >
              Terms &amp; Conditions
            </Typography>
          </a>
        </li>
        <li>
          <a href={`mailto:${CONTACT_EMAIL}`}>
            <Typography
              className="FooterLink"
              variant="subtitle2"
              color="primary.light"
              fontWeight="600"
            >
              Contact Us
            </Typography>
          </a>
        </li>
        {giftCardUrl && (
          <li>
            <a href={giftCardUrl} rel="noopener noreferrer" target="_blank">
              <Typography
                className="FooterLink"
                variant="subtitle2"
                color="primary.light"
                fontWeight="600"
              >
                Gift Cards
              </Typography>
            </a>
          </li>
        )}
      </ul>
      <ul className="cpr-msg">
        <li>
          <Typography className="CopyrightLogo" variant="subtitle2">
            <i className="fa-regular fa-copyright" /> 2023 SaladStop!
            {COUNTRY_NAME}
          </Typography>
        </li>
      </ul>
      <ul className="social-link">
        <li className="kale">
          <a href={FACEBOOK_LINK} rel="noopener noreferrer" target="_blank" aria-label="facebook">
            <FacebookLogo />
          </a>
        </li>
        <li className="kale">
          <a href={INSTAGRAM_LINK} rel="noopener noreferrer" target="_blank" aria-label="instagram">
            <InstaLogo />
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Footer;
