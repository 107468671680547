import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import Button from '../../Button';
import FontAwesome from '../../FontAwesome/FontAwesome';

import iconAmex from './icon-amex.svg';
import iconMasterCard from './icon-master.svg';
import iconVisa from './icon-visa.svg';

import './Payment.css';

function Payment(props) {
  const { loading, onAddPaymentMethodClick } = props;
  return (
    <div className="Payment">
      <Typography variant="calafia1" color="primary" className="SignInTitle visible-lg visible-md">
        Payment
      </Typography>
      <p>Please add a credit card to continue your order</p>
      <ul className="payment-icon">
        <li>
          <img alt="VISA" src={iconVisa} />
        </li>
        <li>
          <img alt="MasterCardCard" src={iconMasterCard} />
        </li>
        <li>
          <img alt="American Express" src={iconAmex} />
        </li>
      </ul>
      <div className="Disclaimer">
        <p>
          A temporary $1 authorization fee will be placed on your card.
          <FontAwesome name="info-circle" />
        </p>
      </div>
      <div>
        <Button
          loading={loading}
          className="PaymentButton btn-primary btn-short"
          onClick={onAddPaymentMethodClick}
        >
          Add card
        </Button>
      </div>
    </div>
  );
}

Payment.propTypes = {
  loading: PropTypes.bool.isRequired,
  onAddPaymentMethodClick: PropTypes.func.isRequired,
};

export default Payment;
