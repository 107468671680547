import PropTypes from 'prop-types';

const paymentMethodShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  methodType: PropTypes.oneOf(['CARD', 'STORED_VALUE']).isRequired,
  cardBrand: PropTypes.oneOf(['Visa', 'MasterCard', 'Amex']).isRequired,
  label: PropTypes.string.isRequired,
  balance: PropTypes.number,
});

export default paymentMethodShape;
