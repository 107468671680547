import React, { useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';
import HtmlToReact from 'html-to-react';

import * as orderActions from '../../actions/order';
import * as orderItemActions from '../../actions/orderItem';
import * as pageActions from '../../actions/page';
import statusEnum from '../../enums/statusEnum';
import * as selectors from '../../sagas/selectors';
import { getSearchParam } from '../../utils/historyUtils';

import ErrorPage from './ErrorPage';
import LandingPageMenu from './LandingPageMenu';

import { cloudFrontService } from '../../services/api';

import './LandingPage.css';

function LandingPage() {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useSelector(selectors.getCurrentLocation);
  const { menuGroupId, metaTags, content, slug, status, errors } = useSelector(
    selectors.getLandingPage,
  );
  const bannerUrl = useSelector(selectors.getLandingPageBannerUrl);
  const { getMenuGroup } = useSelector(selectors.getApiStatus);
  const gglocationId = useSelector(selectors.getOrderGglocationId);
  const disabledGglocationTypes = useSelector(selectors.getLandingPageDisabledGglocationTypes);

  const htmlToReactParser = new HtmlToReact.Parser();
  const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);

  // Process the obtained template, replacing the components as our own
  const processingInstructions = [
    {
      // This is REQUIRED, it tells the parser
      // that we want to insert our React
      // component as a child
      replaceChildren: true,
      shouldProcessNode(node) {
        return node.name === 'landingpagemenu';
      },
      // eslint-disable-next-line react/no-unstable-nested-components
      processNode(node) {
        return <LandingPageMenu menuGroupId={node.attribs.menugroupid} />;
      },
    },
    {
      // Anything else
      shouldProcessNode() {
        return true;
      },
      processNode: processNodeDefinitions.processDefaultNode,
    },
  ];

  const parsedContent = htmlToReactParser.parseWithInstructions(
    content,
    () => true,
    processingInstructions,
  );
  const parsedMeta = htmlToReactParser.parseWithInstructions(
    metaTags,
    () => true,
    processingInstructions,
  );

  // Invoked when components mount and unmount
  useEffect(() => {
    dispatch(pageActions.openLandingPage());
    // Request the landing page for the current slug
    if (!slug || slug !== params.landingPageSlug) {
      dispatch(
        cloudFrontService.landingPages.requestActionCreator({
          landingPageSlug: params.landingPageSlug,
        }),
      );
    }
    // When the component unmounts, set landing page to closed
    return () => dispatch(pageActions.closeLandingPage());
  }, []);

  // Invoked when the status changes of the landing page reducer
  useEffect(() => {
    if (status.landingPages === statusEnum.SUCCESS) {
      // Fetch the menuGroupId for the current landing page
      dispatch(cloudFrontService.getMenuGroup.requestActionCreator({ menuGroupId }));
    }
  }, [status]);

  useEffect(() => {
    if (status.landingPages === statusEnum.SUCCESS && gglocationId) {
      // Reset gglocation if current ggloction type is invalid when
      // first switched to landing page
      if (disabledGglocationTypes.includes(gglocationId.split('_')[0])) {
        dispatch(orderActions.resetGglocation());
      }
    }
  }, [status, gglocationId, disabledGglocationTypes]);

  // When menuGroup is sucessfully retrieved, check queries
  useEffect(() => {
    if (getMenuGroup === statusEnum.SUCCESS) {
      dispatch(orderActions.setLandingPage(params.landingPageSlug));
      const query = getSearchParam(location.search, 'menuItemId');
      // If there is a menuItemId parameter, proceed to create that orderItem
      if (query) {
        dispatch(
          orderItemActions.createOrderItemFromMenuItemId({
            menuItemId: `${query}_${menuGroupId}`,
          }),
        );
        dispatch(pageActions.clearQueryGoToMenuDetails());
      }
    }
  }, [getMenuGroup]);

  // If the landing page hasn't finished fetching, return nothing.
  if (errors.length > 0) {
    if (errors[0].error.message.includes('Not found')) {
      return <ErrorPage />;
    }
    if (errors[0].error.message) {
      return <ErrorPage errorMessage={errors[0].error.message} />;
    }
  } else if (status.landingPages === statusEnum.SUCCESS && getMenuGroup === statusEnum.SUCCESS) {
    return (
      <div className={classnames('LandingPage', { NoBannerImage: !bannerUrl })}>
        <MetaTags>{parsedMeta}</MetaTags>
        {parsedContent}
      </div>
    );
  }
  return <div />;
}

export default LandingPage;
