import { apiCallSuccessChannel } from '@spq/redux-api-client';
import { LOCATION_CHANGE, push, replace } from 'redux-first-history';
import { all, delay, put, select, take, takeEvery } from 'redux-saga/effects';

import * as historyActions from '../actions/history';
import * as loyaltyActions from '../actions/loyalty';
import * as orderActions from '../actions/order';
import * as pageActions from '../actions/page';
import * as userActions from '../actions/user';
import broadcastMessageEnum from '../enums/broadcastMessageEnum';
import sceneEnum from '../enums/sceneEnum';
import statusEnum from '../enums/statusEnum';
import { setSearchParams } from '../utils/historyUtils';
import * as orderItemUtils from '../utils/orderItemUtils';
import * as pageUtils from '../utils/pageUtils';

import * as selectors from './selectors';

import { paymentService } from '../services/api';

function* observePopupCompleted(
  popup,
  popupChannel,
  completeCallback = null,
  closeCallback = null,
) {
  let popupCompleted = false;
  let popupResponse = null;

  let accumulateThreshold = 0;
  const step = 250;
  const maxThreshold = 1000;

  // Listening to channel if complete
  popupChannel.onMessage((message) => {
    popupCompleted = true;
    popupResponse = message;
  });

  while (!popupCompleted) {
    // Check if the popup is being closed by user without completing
    if (popup.closed && !popupCompleted) {
      accumulateThreshold += step;

      // Using accumulate threshold is because when the popup
      // is being redirected to our page, it is being considered
      // as closed, while the result has not yet been parsed.
      // So we will need to wait a while to make sure that
      // it is being completed rather than closed
      if (closeCallback && accumulateThreshold > maxThreshold) {
        popupCompleted = true;
        yield closeCallback();
      }
    }

    // Loop if haven't complete
    if (!popupCompleted) {
      yield delay(step);
    }
  }

  if (popupCompleted) {
    // NOTE: window.close() or popup.close() may not work
    // due to how the popup is being redirected by the 3rd party
    if (!popup.closed) {
      popup.close();
    }
    if (completeCallback && popupResponse) {
      yield completeCallback(popupResponse);
    }
  }
  popupChannel.close();
}

function* confirmPaymentMethodSuccess(response) {
  yield put(pageActions.closePaymentGateway());

  try {
    if (response.success) {
      yield put(paymentService.paymentMethod.requestActionCreator());
      yield take(apiCallSuccessChannel(`${paymentService.paymentMethod}`));

      const defaultPaymentMethod = yield select(selectors.getUserDefaultPaymentMethod);
      yield put(orderActions.selectPaymentMethod(defaultPaymentMethod));

      const currentLocation = yield select(selectors.getCurrentLocation);
      if (currentLocation.pathname === '/account/paymentMethods') {
        yield put(
          push(
            {
              pathname: '/account/paymentMethods',
            },
            {
              savedPaymentMethodId: defaultPaymentMethod.id,
            },
          ),
        );
      } else {
        yield put(userActions.signedIn());
      }
    } else {
      yield put(push({ pathname: '/signIn/payment/failure' }));
    }
  } catch (error) {
    yield put(push({ pathname: '/signIn/payment/failure' }));
  }
}

function* closePaymentPopup() {
  yield put(pageActions.closePaymentGateway());
}

function* openPaymentGateway() {
  // It makes sense here to use take() and wait for the addPaymentMethodUrl request
  // But it sometimes causes a race condition that might be done before we can handle
  let addPaymentMethodStatus;
  let user;
  while (true) {
    // Continuously check the value of addPaymentMethodUrl
    const userStatus = yield select(selectors.getUserStatus);
    addPaymentMethodStatus = userStatus.addPaymentMethod;
    user = yield select(selectors.getUser);

    // exit the loop when the status is a success and there is a payment url
    if (addPaymentMethodStatus === statusEnum.SUCCESS && !!user.addPaymentMethodUrl) {
      break;
    }

    // If addPaymentMethodUrl is not true, wait for some time before checking again
    yield delay(500);
  }

  const windowOpenMethod = {
    POST: pageUtils.openPOSTWindow,
    GET: window.open,
  }[user.addPaymentMethodRedirectHttpMethod];

  const paymentPopup = windowOpenMethod(user.addPaymentMethodUrl);

  const paymentBroadcast = new pageUtils.BroadcastMessageChannel(
    broadcastMessageEnum.PAYMENT_POPUP,
  );
  yield observePopupCompleted(
    paymentPopup,
    paymentBroadcast,
    confirmPaymentMethodSuccess,
    closePaymentPopup,
  );
}

function* openFacebookLogin() {
  yield put(push({ pathname: '/signIn/facebookReset' }));
}

function closeMobileMenu() {
  const handleTransitionEnd = () => {
    document.body.classList.add('isMobileMenuClosed');
    document.body.removeEventListener('transitionend', handleTransitionEnd, false);
    return true;
  };

  if (document.body.classList.contains('isMobileMenuOpen')) {
    document.body.addEventListener('transitionend', handleTransitionEnd, false);
    document.body.classList.remove('isMobileMenuOpen');
  }
}

function openMobileMenu() {
  document.body.classList.remove('isMobileMenuClosed');
  document.body.classList.add('isMobileMenuOpen');
}

function* toggleMobileMenu() {
  const isMobileMenuOpen = yield select(selectors.getIsMobileMenuOpen);
  if (!isMobileMenuOpen) {
    closeMobileMenu();
  } else {
    openMobileMenu();
  }
}

function* openMenuItemPage() {
  const orderItem = yield select(selectors.getOrderItem);
  if (orderItemUtils.isOrderItemValid(orderItem)) {
    if (orderItem.menuItemId) {
      const menuItem = yield select(selectors.selectMenuItem, orderItem.menuItemId);
      if (menuItem.isCyo) {
        yield put(push({ pathname: `/cyo/${menuItem.id}/1` }));
      } else {
        yield put(push({ pathname: `/menu/details/${menuItem.id}` }));
      }
    }
  }
}

function* clearQueryGoToMenuDetails() {
  /* Used in landing pages when we want to remove the menuItemId query from
    history stack to enable going back to the initial landing page */

  const landingPageSlug = yield select(selectors.getCurrentLandingPageSlug);
  // Remove the query from the current URL
  yield put(
    replace({
      pathname: `/site/${landingPageSlug}`,
    }),
  );
  yield take(LOCATION_CHANGE);
  yield put(pageActions.openMenuItemPage());
}

function* returnToMenu() {
  /* This was done to overcome a bug in react-router-redux where calling goBack()
  then replace() would sometimes result in the latter being executed first. */
  const landingPageSlug = yield select(selectors.getCurrentLandingPageSlug);

  yield put(historyActions.goToMenu({ option: 'back' }));
  yield take(LOCATION_CHANGE);
  if (!landingPageSlug) {
    yield put(orderActions.showCart());
  }
}

function* openUpsellScreen() {
  yield put(historyActions.goToMenu({ option: 'back' }));
  yield take(LOCATION_CHANGE);
  yield put(push({ pathname: '/upsell' }));
}

function* handleLocationChange() {
  yield closeMobileMenu();
  yield put(loyaltyActions.clearErrors());
}

function* handleSceneChange({ scene }) {
  const isLoggedIn = yield select(selectors.getUserToken);
  const { search } = yield select(selectors.getCurrentLocation);

  switch (scene) {
    case sceneEnum.LOYALTY:
      if (!isLoggedIn) {
        yield put(userActions.afterSignIn({ pathname: '/loyalty' }));
        yield put(push({ pathname: '/signIn' }));
      } else {
        yield put(push({ pathname: '/loyalty' }));
      }
      break;
    case sceneEnum.ORDER:
      yield put(push({ pathname: '/' }));
      break;
    case sceneEnum.SCAN_RECEIPT:
      if (!isLoggedIn) {
        yield put(
          userActions.afterSignIn({
            pathname: '/loyalty',
            search: setSearchParams(search, { showScanner: true }),
          }),
        );
        yield put(push({ pathname: '/signIn' }));
      } else {
        yield put(pageActions.openScannerModal());
      }
      break;
    default:
      break;
  }
}

function* handleOpenScanner() {
  const { pathname, search } = yield select(selectors.getCurrentLocation);
  yield put(replace({ pathname, search: setSearchParams(search, { showScanner: true }) }));
}

function* handleCloseScanner() {
  const { pathname, search } = yield select(selectors.getCurrentLocation);
  yield put(replace({ pathname, search: setSearchParams(search, { showScanner: false }) }));
}

function* watchOpen() {
  yield takeEvery(pageActions.OPEN_FACEBOOK_LOGIN, openFacebookLogin);
  yield takeEvery(pageActions.OPEN_PAYMENT_GATEWAY, openPaymentGateway);
  yield takeEvery(pageActions.CLOSE_MOBILE_MENU, closeMobileMenu);
  yield takeEvery(historyActions.GO_HOME, closeMobileMenu);
  yield takeEvery(pageActions.OPEN_MOBILE_MENU, openMobileMenu);
  yield takeEvery(pageActions.TOGGLE_MOBILE_MENU, toggleMobileMenu);
  yield takeEvery(pageActions.OPEN_MENU_ITEM_PAGE, openMenuItemPage);
  yield takeEvery(pageActions.RETURN_TO_MENU, returnToMenu);
  yield takeEvery(pageActions.OPEN_UPSELL_SCREEN, openUpsellScreen);
  yield takeEvery(pageActions.CLEAR_QUERY_GO_TO_MENU_DETAILS, clearQueryGoToMenuDetails);
  yield takeEvery(pageActions.SET_PAGE_SCENE, handleSceneChange);
  yield takeEvery(pageActions.OPEN_SCANNER_MODAL, handleOpenScanner);
  yield takeEvery(pageActions.CLOSE_SCANNER_MODAL, handleCloseScanner);
  yield takeEvery('@@router/LOCATION_CHANGE', handleLocationChange);
}

export default function* pageSaga() {
  yield all([watchOpen()]);
}
