import React from 'react';
import Pusher from 'react-pusher';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as apiActions from '../../actions/api';
import * as userActions from '../../actions/user';
import pusherOperationEnum from '../../enums/pusherOperationEnum';

import { ECOM_CUSTOMER_PUSHER_CHANNEL } from '../../settings';

function PusherProvider({
  uuid,
  addOrder,
  updateOrder,
  updateStoreBusy,
  enableIngredient,
  disableIngredient,
}) {
  return (
    <>
      {/* Order Pusher */}
      {uuid && (
        <>
          <Pusher channel={`private-${uuid}`} event="order-created" onUpdate={addOrder} />
          <Pusher channel={`private-${uuid}`} event="order-updated" onUpdate={updateOrder} />
        </>
      )}

      {/* StoreBusy Pusher */}
      <Pusher
        channel={ECOM_CUSTOMER_PUSHER_CHANNEL}
        event="ecom:webclient:store-busy"
        onUpdate={updateStoreBusy}
      />

      {/* DisabledIngredient Pusher */}
      <Pusher
        channel={ECOM_CUSTOMER_PUSHER_CHANNEL}
        event="ecom:webclient:ingredient-reenabled"
        onUpdate={(ingredient) =>
          enableIngredient({
            ingredient,
            operation: pusherOperationEnum.ENABLED,
          })
        }
      />
      <Pusher
        channel={ECOM_CUSTOMER_PUSHER_CHANNEL}
        event="ecom:webclient:ingredient-disabled"
        onUpdate={(ingredient) =>
          disableIngredient({
            ingredient,
            operation: pusherOperationEnum.DISABLED,
          })
        }
      />
    </>
  );
}

PusherProvider.propTypes = {
  uuid: PropTypes.string,
  addOrder: PropTypes.func.isRequired,
  updateOrder: PropTypes.func.isRequired,
  updateStoreBusy: PropTypes.func.isRequired,
  enableIngredient: PropTypes.func.isRequired,
  disableIngredient: PropTypes.func.isRequired,
};

PusherProvider.defaultProps = {
  uuid: undefined,
};

const mapStateToProps = (state) => ({
  uuid: state.user.uuid,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addOrder: userActions.addOrder,
      updateOrder: userActions.updateOrder,
      updateStoreBusy: apiActions.updateStoreBusyFromPusher,
      enableIngredient: apiActions.updateDisabledIngredientFromPusher,
      disableIngredient: apiActions.updateDisabledIngredientFromPusher,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PusherProvider);
