import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as apiActions from '../../../actions/api';
import * as historyActions from '../../../actions/history';
import * as orderActions from '../../../actions/order';
import { getSearchParam } from '../../../utils/historyUtils';

import paymentMethodShape from '../../../shapes/paymentMethodShape';
import routerShape, { routerLocationShape } from '../../../shapes/routerShape';

import PaymentFailure from '../../../components/SignIn/PaymentFailure';
import withRouter from '../../WithRouter';

class PaymentFailureContainer extends Component {
  static propTypes = {
    paymentMethods: PropTypes.objectOf(paymentMethodShape).isRequired,
    withToken: PropTypes.func.isRequired,
    goToMenu: PropTypes.func.isRequired,
    /* React Router Props */
    location: routerLocationShape.isRequired,
    router: routerShape.isRequired,
  };

  get paymentMethodId() {
    const { location } = this.props;

    return getSearchParam(location?.search, 'paymentMethodId');
  }

  get paymentMethod() {
    const { paymentMethods } = this.props;

    return paymentMethods[this.paymentMethodId];
  }

  handlePaymentMethodAddClick = () => {
    const { router } = this.props;

    router.replace({ pathname: '/signIn/payment' });
  };

  handleOrderRetryClick = () => {
    const { withToken, goToMenu } = this.props;

    goToMenu();
    withToken(orderActions.placeOrderDispatch());
  };

  render() {
    return (
      <PaymentFailure
        paymentMethod={this.paymentMethod}
        onPaymentMethodAddClick={this.handlePaymentMethodAddClick}
        onOrderRetryClick={this.handleOrderRetryClick}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  paymentMethods: state.api.paymentMethods,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      withToken: apiActions.withToken,
      goToMenu: historyActions.goToMenu,
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentFailureContainer));
