import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import gglocationTypeEnum from '../../enums/gglocationTypeEnum';
import * as orderUtils from '../../utils/orderUtils';

import gglocationShape from '../../shapes/gglocationShape';
import orderDeliveryInfoShape from '../../shapes/orderDeliveryInfoShape';
import userOrderItemShape from '../../shapes/userOrderItemShape';

import NameFavorite from '../../containers/Order/NameFavorite';
import OrderItem from '../../containers/Order/OrderItem';
import OrderPayment from '../../containers/OrderPayment';
import Button from '../Button';
import Info from '../Info';
import GglocationInfo from '../PickupTimeSelector/GglocationInfo';
import SectionSeparator from '../SectionSeparator';
import TwoColumns from '../TwoColumns';
import OrderCancel from './OrderCancel';
import OrderCancelButton from './OrderCancelButton';
import OrderCancelled from './OrderCancelled';
import OrderDeliveryInfo from './OrderDeliveryInfo';
import OrderProgressBar from './OrderProgressBar';
import OrderStatus from './OrderStatus';
import PickFavorite from './PickFavorite';

import './Order.css';

function Order(props) {
  const {
    orderId,
    gglocation,
    orderItems,
    totalPrice,
    status,
    pickupCode,
    created,
    readyTime,
    readyTimeRange,
    cancellationRemark,
    favoriteOrderItem,
    deliveryAddressLabel,
    deliveryInfo,
    openingHours,
    lastOrderTime,
    showPickFavoriteModal,
    showOrderCancelModal,
    showOrderCancelledModal,
    onNameFavoriteCancel,
    onFavoriteRequest,
    onNameFavoriteSave,
    onPickFavoriteCancel,
    onOrderCancelModalHide,
    onOrderCancelledModalHide,
    onOrderCancelConfirm,
    onOrderCancelRequest,
    onOrderReplace,
    showOrderReplace,
  } = props;
  const isDeliveryOrder = deliveryAddressLabel !== null;
  const isStation = gglocation?.gglocationType === gglocationTypeEnum.PARTNER;
  const pickUpName = isStation ? 'Station Pickup' : 'Pickup';
  const AddressSticker = (
    <>
      <Typography
        variant="h2"
        color="secondary.dark"
        className="OrderAddressDiningChoice"
        fontSize="2.5em"
      >
        {isDeliveryOrder ? 'Delivery' : pickUpName}
      </Typography>
      <GglocationInfo
        name={isDeliveryOrder ? deliveryAddressLabel : gglocation.name}
        address1={isDeliveryOrder ? '' : gglocation.address1}
        address2={isDeliveryOrder ? '' : gglocation.address2}
        coordinates={gglocation?.coordinates}
        gglocation={gglocation}
        isDeliveryOrder={isDeliveryOrder}
        openingHours={openingHours}
        lastOrderTime={lastOrderTime}
        readyTime={readyTime}
        fromOrder
      />
    </>
  );

  return (
    <div className="Order">
      <NameFavorite
        favoriteOrderItem={favoriteOrderItem}
        onHide={onNameFavoriteCancel}
        onSave={onNameFavoriteSave}
      />
      <PickFavorite
        orderItems={orderItems}
        show={showPickFavoriteModal}
        onFavorite={onFavoriteRequest}
        onHide={onPickFavoriteCancel}
      />
      <OrderCancel
        show={showOrderCancelModal}
        onOrderCancelConfirm={onOrderCancelConfirm}
        onHide={onOrderCancelModalHide}
      />
      <OrderCancelled
        show={showOrderCancelledModal}
        onHide={onOrderCancelledModalHide}
        cancellationRemark={cancellationRemark}
      />
      <TwoColumns>
        <li className="column kale">
          <OrderProgressBar
            status={status}
            readyTime={readyTime}
            isDeliveryOrder={isDeliveryOrder}
          />
          {!orderUtils.isOrderStatusCancelled(status) && (
            <Typography variant="subtitle2" fontStyle="italic" className="FreshnessMessage">
              Our meals are best consumed within 2 hours
            </Typography>
          )}
          <OrderStatus
            status={status}
            price={totalPrice}
            readyTime={readyTime}
            readyTimeRange={readyTimeRange}
            isDeliveryOrder={isDeliveryOrder}
            gglocationPhone={gglocation.phone}
          />
          {orderUtils.isOrderStatusRequested(status) && (
            <div className="Disclaimer">
              <Typography variant="body2">
                <Info /> You may navigate away from this page. Your order will not be affected.
              </Typography>
            </div>
          )}
          <div className="orderAddressSticker">{AddressSticker}</div>
          {isDeliveryOrder && (
            <OrderDeliveryInfo
              deliveryInfo={deliveryInfo}
              isAccepted={orderUtils.isOrderStatusAccepted(status)}
            />
          )}
          <div className="payment">
            <OrderPayment orderId={orderId} />
          </div>
        </li>
        <li className="column">
          <div className="order">
            <div className="visible-lg visible-md visible-sm">
              <div className="OrderTitle">
                <Typography variant="calafia1" color="primary.dark" className="SlantedOrderTitle">
                  Your Order
                </Typography>
                {orderUtils.isOrderStatusRequested(status) && (
                  <OrderCancelButton
                    createTime={created}
                    orderStatus={status}
                    onCancel={onOrderCancelRequest}
                  />
                )}
                {pickupCode && !orderUtils.isOrderStatusRequested(status) && (
                  <Typography variant="h1" color="secondary.dark" className="SharpGroteskBold15">
                    {pickupCode}
                  </Typography>
                )}
              </div>
            </div>
            <SectionSeparator
              className="visible-xs CalafiaSectionTitle"
              typographyColor="primary"
              typographyVariant="calafia2"
            >
              Your Order
            </SectionSeparator>
            <ul className="OrderItems kale">
              {orderItems.map((orderItem) => (
                // eslint-disable-next-line react/jsx-key
                <OrderItem orderItem={orderItem} onFavorite={onFavoriteRequest} />
              ))}
            </ul>
          </div>
          {pickupCode && (
            <div className="code visible-xs visble-sm">
              <SectionSeparator typographyColor="secondary.dark" className="SharpGroteskSmBold15">
                {isDeliveryOrder ? ' Delivery code' : 'Pick up code'}
              </SectionSeparator>
              <Typography variant="h3">{pickupCode}</Typography>
            </div>
          )}
          {orderUtils.isOrderStatusUserCancelled(status) && showOrderReplace && (
            <div className="reorder">
              <Button className="btn-round" onClick={onOrderReplace}>
                Re-place order
              </Button>
            </div>
          )}
        </li>
      </TwoColumns>
      {orderUtils.isOrderStatusRequested(status) && (
        <OrderCancelButton
          createTime={created}
          orderStatus={status}
          onCancel={onOrderCancelRequest}
        />
      )}
    </div>
  );
}

Order.propTypes = {
  orderId: PropTypes.string.isRequired,
  gglocation: gglocationShape.isRequired,
  orderItems: PropTypes.arrayOf(userOrderItemShape).isRequired,
  totalPrice: PropTypes.number.isRequired,
  status: PropTypes.number.isRequired,
  pickupCode: PropTypes.string,
  created: PropTypes.string,
  readyTime: PropTypes.string,
  readyTimeRange: PropTypes.string,
  cancellationRemark: PropTypes.string,
  favoriteOrderItem: userOrderItemShape,
  deliveryAddressLabel: PropTypes.string,
  deliveryInfo: orderDeliveryInfoShape,
  openingHours: PropTypes.string,
  lastOrderTime: PropTypes.string,
  showPickFavoriteModal: PropTypes.bool.isRequired,
  showOrderCancelModal: PropTypes.bool.isRequired,
  showOrderCancelledModal: PropTypes.bool.isRequired,
  onNameFavoriteCancel: PropTypes.func.isRequired,
  onFavoriteRequest: PropTypes.func.isRequired,
  onNameFavoriteSave: PropTypes.func.isRequired,
  onPickFavoriteCancel: PropTypes.func.isRequired,
  onOrderCancelModalHide: PropTypes.func.isRequired,
  onOrderCancelledModalHide: PropTypes.func.isRequired,
  onOrderCancelConfirm: PropTypes.func.isRequired,
  onOrderCancelRequest: PropTypes.func.isRequired,
  onOrderReplace: PropTypes.func.isRequired,
  showOrderReplace: PropTypes.bool.isRequired,
};

Order.defaultProps = {
  pickupCode: null,
  created: null,
  readyTime: null,
  readyTimeRange: null,
  deliveryAddressLabel: null,
  deliveryInfo: null,
  cancellationRemark: null,
  favoriteOrderItem: null,
  openingHours: null,
  lastOrderTime: null,
};

export default Order;
