import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import PaymentSpinner from '../../components/PaymentSpinner';

function PaymentSpinnerContainer(props) {
  const { isPaymentGatewayOpen } = props;

  return <PaymentSpinner show={isPaymentGatewayOpen} />;
}

PaymentSpinnerContainer.propTypes = {
  isPaymentGatewayOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isPaymentGatewayOpen: state.page.isPaymentGatewayOpen,
});

export default connect(mapStateToProps, undefined)(PaymentSpinnerContainer);
