import React from 'react';
import PropTypes from 'prop-types';

import { landingPageTopBarStyles } from '../../shapes/landingPageStyleShapes';

import CartButton from '../../containers/TopBar/CartButton';
import TopBarTitle from '../../containers/TopBar/TopBarTitle';
import TopLeftButton from '../../containers/TopBar/TopLeftButton';
import Button from '../Button';
import LoyaltyTabs from '../LoyaltyTabs/LoyaltyTabs';
import MenuMobile from '../MenuMobile';
import UserButton from './UserButton';

import { ReactComponent as HistoryIcon } from '../../images/icon-history.svg';

import './TopBar.css';
/* Rules for showing and hiding TopBar buttons */
import './TopBarRules.css';

function TopBar(props) {
  const {
    isCartShown,
    landingPageStyles,
    isLandingPageOpen,
    isMobileMenuOpen,
    orderStarted,
    loggedIn,
    isDeliveryAvailable,
    onHomeClick,
    onBackClick,
    onSignInClick,
    onSignOutClick,
    onMobileMenuClose,
    onScanHistoryClick,
    onExternalBackClick,
  } = props;

  return (
    <div className="TopBar" style={landingPageStyles && landingPageStyles.backgroundStyle}>
      <TopLeftButton
        isCartShown={isCartShown}
        onHomeClick={onHomeClick}
        onExternalBackClick={onExternalBackClick}
      />
      <TopBarTitle isCartShown={isCartShown} isLandingPageOpen={isLandingPageOpen} />
      <div className="TopBarRightButton">
        <LoyaltyTabs type="bar" />
        {orderStarted && (
          <CartButton landingPageStyles={landingPageStyles} isLandingPageOpen={isLandingPageOpen} />
        )}
        <UserButton
          loggedIn={loggedIn}
          onSignInClick={onSignInClick}
          onSignOutClick={onSignOutClick}
          isLandingPageOpen={isLandingPageOpen}
          landingPageStyles={landingPageStyles && landingPageStyles.signInStyle}
        />
        {loggedIn && (
          <Button className="ScanHistoryButton" onClick={onScanHistoryClick}>
            <HistoryIcon />
          </Button>
        )}
        <Button className="TopRightBackButton" fa="times" onClick={onBackClick} />
      </div>
      <MenuMobile
        loggedIn={loggedIn}
        isOpen={isMobileMenuOpen}
        isDeliveryAvailable={isDeliveryAvailable}
        onHomeClick={onHomeClick}
        onSignInClick={onSignInClick}
        onSignOutClick={onSignOutClick}
        onMobileMenuClose={onMobileMenuClose}
      />
    </div>
  );
}

TopBar.propTypes = {
  isCartShown: PropTypes.bool,
  landingPageStyles: landingPageTopBarStyles.isRequired,
  isMobileMenuOpen: PropTypes.bool.isRequired,
  orderStarted: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  isDeliveryAvailable: PropTypes.bool.isRequired,
  isLandingPageOpen: PropTypes.bool.isRequired,
  onHomeClick: PropTypes.func.isRequired,
  onBackClick: PropTypes.func.isRequired,
  onSignInClick: PropTypes.func.isRequired,
  onSignOutClick: PropTypes.func.isRequired,
  onMobileMenuClose: PropTypes.func.isRequired,
  onScanHistoryClick: PropTypes.func.isRequired,
  onExternalBackClick: PropTypes.func,
};

TopBar.defaultProps = {
  isCartShown: false,
  onExternalBackClick: null,
};

export default TopBar;
