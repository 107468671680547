import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import statusEnum from '../../../enums/statusEnum';

import Phone from '../../../components/SignIn/Phone';

import { oauthService } from '../../../services/api';
import { DEFAULT_COUNTRY_CODE } from '../../../settings';

class PhoneContainer extends Component {
  static propTypes = {
    createPhone: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
    errors: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  constructor(props, context) {
    const { token } = props;
    const { router } = context;
    if (token === null) {
      router.push('/signIn');
    }

    super(props);

    this.state = {
      countryCode: DEFAULT_COUNTRY_CODE,
      phone: '',
      isCountryCodeValid: true,
      isPhoneValid: true,
    };
  }

  componentDidUpdate(prevProps) {
    const { errors } = this.props;

    this.handleErrors(prevProps.errors, errors);
  }

  handleCountryCodeChange = (event) => {
    const countryCode = event.target.value;

    if (countryCode.length === 0) {
      this.setState({ countryCode: '+' });
      return null;
    }
    if (countryCode.length === 1 && countryCode !== '+') {
      this.setState({ countryCode: `+${countryCode}` });
      return null;
    }
    if (/^\+(\d+|-| |)+$/.test(countryCode) === false) {
      return null;
    }

    this.setState({ countryCode });

    return true;
  };

  handlePhoneChange = (event) => {
    const phone = event.target.value;

    if (/^(\d+|-| |)+$/.test(phone) === false) return null;
    this.setState({ phone });

    return true;
  };

  handleFormSubmit = (event) => {
    const { createPhone } = this.props;
    const { countryCode, phone } = this.state;
    event.preventDefault();

    const isCountryCodeValid = countryCode.length > 1;
    const isPhoneValid = phone.length > 0;
    this.setState({ error: null, isCountryCodeValid, isPhoneValid });

    if (!isCountryCodeValid || !isPhoneValid) {
      this.setState({ error: 'Please enter a valid phone number.' });
      return false;
    }

    const number = this.parsedNumber;
    const stateOptions = {
      pendingPhone: number,
    };
    createPhone({ body: { number }, stateOptions });

    return true;
  };

  handleErrors(previousErrors, newErrors) {
    if (newErrors.length === 0 || previousErrors.length === newErrors.length) return null;
    this.setState({ error: newErrors[0].error.message });

    return null;
  }

  get parsedNumber() {
    const { countryCode, phone } = this.state;
    const phoneNumber = `+${(countryCode + phone).match(/\d/g).join('')}`;

    return phoneNumber;
  }

  get isCountryCodeValid() {
    const { isCountryCodeValid } = this.state;
    const { status } = this.props;

    if (status === statusEnum.ERROR) return false;

    return isCountryCodeValid;
  }

  get isPhoneValid() {
    const { isPhoneValid } = this.state;
    const { status } = this.props;

    if (status === statusEnum.ERROR) return false;

    return isPhoneValid;
  }

  render() {
    const { countryCode, phone, error } = this.state;

    return (
      <Phone
        countryCode={countryCode}
        phone={phone}
        error={error}
        isCountryCodeValid={this.isCountryCodeValid}
        isPhoneValid={this.isPhoneValid}
        onCountryCodeChange={this.handleCountryCodeChange}
        onPhoneChange={this.handlePhoneChange}
        onFormSubmit={this.handleFormSubmit}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.user.token,
  status: state.user.status.createPhone,
  errors: state.user.errors,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createPhone: oauthService.createPhone.requestActionCreator,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PhoneContainer);
