import { rehydrateReducer } from '@spq/utils';
import { REHYDRATE } from 'redux-persist/lib/constants';

import * as apiActions from '../actions/api';
import * as userActions from '../actions/user';
import semVerEnum from '../enums/semVerEnum';
import statusEnum from '../enums/statusEnum';
import { compareSemVerDiff } from '../utils/commonUtils';

import packageInfo from '../../package.json';

const defaultState = Object.freeze({
  signedIn: false,
  firstName: null,
  lastName: null,
  email: null,
  password: null,
  phone: null,
  pendingPhone: null,
  phoneConfirmed: false,
  dob: null,
  gender: null,
  socialLogin: null,
  uuid: null,
  token: null,
  isPasswordModalShown: false,
  isResendModalShown: false,
  isInvalidTokenModalShown: false,
  isEmailVerificationPopUpShown: false,
  isVersionUpdated: false,
  customerAddresses: {},
  customerAddressIds: [],
  afterSignIn: { pathname: '/locations' },
  beforeSignIn: { pathname: '/locations' },
  registration: {
    firstName: '',
    lastName: '',
    email: '',
  },
  userStatus: null,
  marketingOptIn: false,
  status: {
    checkUserExists: statusEnum.INITIAL,
    profile: statusEnum.INITIAL,
    signUp: statusEnum.INITIAL,
    login: statusEnum.INITIAL,
    facebookLogin: statusEnum.INITIAL,
    forgetPassword: statusEnum.INITIAL,
    createPhone: statusEnum.INITAL,
    resendPin: statusEnum.INITIAL,
    verifyPin: statusEnum.INITIAL,
    addPaymentMethodUrl: statusEnum.INITIAL,
    removePaymentMethod: statusEnum.INITIAL,
    updateProfile: statusEnum.INITIAL,
    changePassword: statusEnum.INITIAL,
    addFavoriteMenuItem: statusEnum.INITIAL,
    removeFavoriteMenuItem: statusEnum.INITIAL,
    editFavoriteMenuItem: statusEnum.INITIAL,
    customerAddresses: statusEnum.INITIAL,
    addCustomerAddress: statusEnum.INITIAL,
    removeCustomerAddress: statusEnum.INITIAL,
    cancelOrder: statusEnum.INITIAL,
    updatePartnerEmail: statusEnum.INITIAL,
    reverifyEmail: statusEnum.INITIAL,
  },
  errors: [],
  version: packageInfo.version,
});

// isVersionUpdated is used to check if the version is being updated
// And it allow devs to perform actions after version update, like fetching some details
// NOTE: it shall be cleaned or not set as false between hydration if not update is found.
const persistAllUserState = ({ state, incoming, isVersionUpdated = true }) =>
  Object.freeze({
    ...rehydrateReducer(
      [
        'signedIn',
        'firstName',
        'lastName',
        'email',
        'phone',
        'phoneConfirmed',
        'dob',
        'gender',
        'socialLogin',
        'uuid',
        'token',
        'paymentToken',
        'afterSignIn',
        'beforeSignIn',
        'customerAddresses',
        'customerAddressIds',
        'registration',
        'userStatus',
        'marketingOptIn',
      ],
      state,
      incoming,
    ),
    status: {
      ...state.status,
      profile: incoming.status.profile,
    },
    isVersionUpdated,
  });

const persistUpdatedUserState = ({ state, incoming }) => {
  const semVerDiff = compareSemVerDiff(incoming.version, state.version);

  /**
   * We can selectively handling how to rehydrate the
   * reducer state of different version.
   * By default, incoming state will be fully rehydrated.
   *
   * You may use a more targeting method, such as:
   * @example
   * ```js
   * import semverLt from 'semver/functions/lt';
   * import semverGte from 'semver/functions/gte';
   *
   * if (
   *   semverLt(incoming.version, '4.0.0')
   *   && semverGte(incoming.version, '3.0.0')
   * ) {
   *   // Transform the state properly
   *   const newState = transformUserStateV3ToV4(incoming);
   *   return Object.freeze({ ...newState });
   * }
   * ```
   *
   * **NOTE**: Always return a freeze object.
   */
  if (semVerDiff === semVerEnum.SAME) {
    return persistAllUserState({ state, incoming, isVersionUpdated: false });
  }
  if (semVerDiff === semVerEnum.MAJOR) {
    return persistAllUserState({ state, incoming });
  }
  if (semVerDiff === semVerEnum.MINOR) {
    return persistAllUserState({ state, incoming });
  }
  if (semVerDiff === semVerEnum.PATCH) {
    return persistAllUserState({ state, incoming });
  }
  if (semVerDiff === semVerEnum.PREMAJOR) {
    return persistAllUserState({ state, incoming });
  }
  if (semVerDiff === semVerEnum.PREMINOR) {
    return persistAllUserState({ state, incoming });
  }
  if (semVerDiff === semVerEnum.PREPATCH) {
    return persistAllUserState({ state, incoming });
  }
  if (semVerDiff === semVerEnum.PRERELEASE) {
    return persistAllUserState({ state, incoming });
  }
  return Object.freeze({ ...state });
};

// eslint-disable-next-line default-param-last
export default function userReducer(state = defaultState, action) {
  switch (action.type) {
    case REHYDRATE: {
      /* Don't restore user data if user is being logged in */
      if (state.status.login !== statusEnum.INITIAL) {
        return Object.freeze({ ...state });
      }

      const incoming = action.payload && action.payload.user;
      if (incoming) {
        if (incoming.version === state.version) {
          return persistAllUserState({ state, incoming, isVersionUpdated: false });
        }
        // Selectively rehydrate when the version is different
        return persistUpdatedUserState({ state, incoming });
      }
      return Object.freeze({ ...state });
    }

    case userActions.SIGN_IN_DISPATCH: {
      return Object.freeze({
        ...state,
        status: {
          ...state.status,
          checkUserExists: statusEnum.REQUESTED,
        },
      });
    }
    case userActions.SHOW_PASSWORD_MODAL: {
      return Object.freeze({
        ...state,
        isPasswordModalShown: true,
      });
    }
    case userActions.HIDE_PASSWORD_MODAL: {
      return Object.freeze({
        ...state,
        isPasswordModalShown: false,
      });
    }
    case userActions.HIDE_RESEND_MODAL: {
      return Object.freeze({
        ...state,
        isResendModalShown: false,
      });
    }
    case userActions.SIGNED_IN: {
      return Object.freeze({
        ...state,
        signedIn: true,
      });
    }
    case userActions.SIGN_OUT: {
      return Object.freeze({
        ...state,
        signedIn: false,
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        phoneConfirmed: false,
        socialLogin: null,
        token: null,
        uuid: null,
        customerAddresses: {},
        customerAddressIds: [],
        afterSignIn: { pathname: '/locations' },
        beforeSignIn: { pathname: '/locations' },
        userStatus: null,
        marketingOptIn: false,
        registration: {
          firstName: '',
          lastName: '',
          email: '',
        },
        status: {
          ...state.status,
          profile: statusEnum.INITIAL,
        },
      });
    }
    case userActions.SET_REGISTRATION_FIRST_NAME: {
      return Object.freeze({
        ...state,
        registration: {
          ...state.registration,
          firstName: action.firstName,
        },
      });
    }
    case userActions.SET_REGISTRATION_LAST_NAME: {
      return Object.freeze({
        ...state,
        registration: {
          ...state.registration,
          lastName: action.lastName,
        },
      });
    }
    case userActions.SET_REGISTRATION_EMAIL: {
      return Object.freeze({
        ...state,
        registration: {
          ...state.registration,
          email: action.email,
        },
      });
    }
    case userActions.SHOW_INVALID_TOKEN_MODAL: {
      return Object.freeze({
        ...state,
        isInvalidTokenModalShown: true,
      });
    }
    case userActions.HIDE_INVALID_TOKEN_MODAL: {
      return Object.freeze({
        ...state,
        isInvalidTokenModalShown: false,
      });
    }
    case userActions.SET_IS_EMAIL_VERIFICATION_POP_UP_SHOWN: {
      return Object.freeze({
        ...state,
        isEmailVerificationPopUpShown: action.isEmailVerificationPopUpShown,
      });
    }
    case userActions.AFTER_SIGN_IN: {
      return Object.freeze({
        ...state,
        afterSignIn: action.link,
      });
    }
    case userActions.BEFORE_SIGN_IN: {
      return Object.freeze({
        ...state,
        beforeSignIn: action.link,
      });
    }
    case apiActions.RESET_STATUS:
      if (action.reducer !== 'user') return state;

      return Object.freeze({
        ...state,
        status: {
          ...state.status,
          [action.field]: statusEnum.INITIAL,
        },
      });
    case userActions.CLEAR_VERSION_UPDATE_FLAG: {
      return Object.freeze({
        ...state,
        isVersionUpdated: false,
      });
    }
    default:
      return state;
  }
}
