import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as historyUtils from '../../utils/historyUtils';

import historyShape from '../../shapes/historyShape';

import App from '../../components/App';

import * as settings from '../../settings';

const tagManagerArgs = {
  gtmId: settings.GTM_ID,
};
TagManager.initialize(tagManagerArgs);

if (Object.keys(settings).includes('GA_ID')) {
  // eslint-disable-next-line global-require
  const { GA_ID } = require('../../settings');
  ReactGA.initialize(GA_ID);
}

class AppContainer extends Component {
  static propTypes = {
    loggedIn: PropTypes.bool.isRequired,
    history: historyShape.isRequired,
  };

  get currentLocation() {
    const { history } = this.props;

    return history.locations[history.currentIndex];
  }

  get currentFlow() {
    return historyUtils.findRoutesFlow(this.currentLocation?.flow);
  }

  get currentRoute() {
    return historyUtils.findLocationRoute(this.currentLocation, this.currentFlow.routes);
  }

  get className() {
    const { loggedIn } = this.props;

    return classnames(this.currentRoute?.className, { loggedIn });
  }

  render() {
    return (
      <App className={this.className}>
        <Outlet />
      </App>
    );
  }
}

const mapStateToProps = (state) => ({
  history: state.history,
  loggedIn: !!state.user.token,
});

export default connect(mapStateToProps, undefined)(AppContainer);
