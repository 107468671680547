import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as apiActions from '../../../actions/api';
import * as orderActions from '../../../actions/order';
import * as pageActions from '../../../actions/page';
import statusEnum from '../../../enums/statusEnum';

import paymentMethodShape from '../../../shapes/paymentMethodShape';
import { routerLocationShape } from '../../../shapes/routerShape';

import PaymentMethods from '../../../components/Account/PaymentMethods';

import { paymentService } from '../../../services/api';

class PaymentMethodsContainer extends Component {
  static propTypes = {
    paymentMethodIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    paymentMethods: PropTypes.objectOf(paymentMethodShape).isRequired,
    removePaymentMethodStatus: PropTypes.number.isRequired,
    removePaymentMethod: PropTypes.func.isRequired,
    defaultPaymentMethod: PropTypes.func.isRequired,
    addPaymentMethod: PropTypes.func.isRequired,
    selectPaymentMethod: PropTypes.func.isRequired,
    selectedPaymentMethod: PropTypes.func.isRequired,
    setDefaultPaymentMethod: PropTypes.func.isRequired,
    withToken: PropTypes.func.isRequired,
    /* React Router props */
    location: routerLocationShape.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      notification: undefined,
      error: undefined,
    };
    this.statusTimeout = null;
  }

  componentDidMount() {
    const { addPaymentMethod } = this.props;
    addPaymentMethod();
  }

  componentDidUpdate(prevProps) {
    const { removePaymentMethodStatus } = this.props;
    const { location } = this.props;

    if (prevProps.removePaymentMethodStatus !== removePaymentMethodStatus) {
      this.handleRemovePaymentMethodStatusUpdate(removePaymentMethodStatus);
    }
    if (prevProps.location.state?.savedPaymentMethodId !== location.state?.savedPaymentMethodId) {
      this.savedPaymentMethodLabel();
    }
  }

  componentWillUnmount() {
    if (this.statusTimeout) {
      clearTimeout(this.statusTimeout);
      this.statusTimeout = null;
    }
  }

  get paymentMethods() {
    const { paymentMethodIds, paymentMethods } = this.props;

    return paymentMethodIds.map((paymentMethodId) => paymentMethods[paymentMethodId]);
  }

  setStatusTimeout = (status, timeout = 5000) => {
    setTimeout(() => {
      this.setState({ [status]: undefined, error: undefined });
    }, timeout);
  };

  handleRemovePaymentMethod = (id) => {
    const { removePaymentMethod, selectPaymentMethod, selectedPaymentMethod } = this.props;

    if (selectedPaymentMethod && id === selectedPaymentMethod.id) {
      selectPaymentMethod(null);
    }
    removePaymentMethod({ id });
  };

  handleAddPaymentMethod = () => {
    const { withToken } = this.props;

    withToken(pageActions.openPaymentGateway());
  };

  handleSetDefault = (uuid) => {
    const { setDefaultPaymentMethod } = this.props;

    setDefaultPaymentMethod({ uuid });
  };

  handleRemovePaymentMethodStatusUpdate = (status) => {
    if (status === statusEnum.SUCCESS) {
      this.setState({ notification: 'Payment method removed successfully', error: undefined });
      this.setStatusTimeout('notification');
    } else if (status === statusEnum.ERROR) {
      this.setState({ notification: undefined, error: "Couldn't remove a payment method" });
      this.setStatusTimeout('notification');
    } else {
      this.setState({ notification: undefined, error: undefined });
    }
  };

  savedPaymentMethodLabel() {
    const { location } = this.props;
    const { savedPaymentMethodId } = location.state || '';
    const savedPaymentMethod = this.paymentMethods.find(
      (paymentMethod) => paymentMethod.id === savedPaymentMethodId,
    );

    const savedLabel = savedPaymentMethod?.label;

    if (savedLabel) {
      this.setState({
        notification: `Payment method ${savedLabel} saved successfully`,
        error: undefined,
      });
    }
    this.setStatusTimeout('notification');
  }

  render() {
    const { notification, error } = this.state;
    const { defaultPaymentMethod } = this.props;

    return (
      <PaymentMethods
        paymentMethods={this.paymentMethods}
        savedPaymentMethodLabel={this.savedPaymentMethodLabel}
        notification={notification}
        error={error}
        defaultPaymentMethod={defaultPaymentMethod}
        onPaymentMethodRemoveClick={this.handleRemovePaymentMethod}
        onSetDefaultClick={this.handleSetDefault}
        onPaymentMethodAddClick={this.handleAddPaymentMethod}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  location: state.router.location,
  paymentMethodIds: state.api.paymentMethodIds,
  paymentMethods: state.api.paymentMethods,
  defaultPaymentMethod: state.api.defaultPaymentMethod,
  selectedPaymentMethod: state.order.selectedPaymentMethod,
  removePaymentMethodStatus: state.user.status.removePaymentMethod,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      withToken: apiActions.withToken,
      removePaymentMethod: paymentService.removePaymentMethod.requestActionCreator,
      addPaymentMethod: paymentService.addPaymentMethod.requestActionCreator,
      setDefaultPaymentMethod: paymentService.setDefaultPaymentMethod.requestActionCreator,
      selectPaymentMethod: orderActions.selectPaymentMethod,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodsContainer);
